interface FilterOption {
  optionCode: string
  optionText: string
  node?: FilterOption[]
}

// Function to get selected nodes text, used only for Activity ordering filter
export const getActiveNodes = (
  selectedItems: string[],
  hierarchicalData: FilterOption[],
  type: string = 'optionText'
): string[] => {
  const selectedSet = new Set<string>(selectedItems)
  const activeNodes: string[] = []

  function traverse (node: FilterOption): string[] {
    let hasActiveChild = false
    const isSelected = selectedSet.has(node[type as keyof FilterOption] as string) // Check if current node is selected

    const childResults: string[] = []
    if (node.node) {
      for (const child of node.node) {
        const childActive = traverse(child) // Recurse into children
        if (childActive.length > 0) {
          hasActiveChild = true
          childResults.push(...childActive)
        }
      }
    }

    if (isSelected) {
      return [node[type as keyof FilterOption] as string] // Return the parent node
    }

    // If all children are selected, return only the parent
    if (childResults.length > 0 && node.node && childResults.length === node.node.length) {
      return [node[type as keyof FilterOption] as string]
    }

    if (hasActiveChild) {
      return childResults
    }

    return []
  }

  for (const item of hierarchicalData) {
    const activeItemsFromNode = traverse(item)
    if (activeItemsFromNode.length > 0) {
      activeNodes.push(...activeItemsFromNode)
    }
  }

  return [...activeNodes]
}
