import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Checkbox, Icon, Select, Text, Tooltip } from '@creditinfo-ui/atoms'

import { apiGet } from '../../hooks/useApi'
import Menu from '../Menu'
import StepsIndicator from './StepsIndicator'
import { prepareStyle } from '@creditinfo-ui/fela'
import { useSessionStorage } from '../../hooks/useStorage'

interface Props {
  tabs: string[]
  forInternational?: boolean
  selectedDataFields: DataField[]
  setSelectedDataFields: Function
  quartersIncluded: number
  setQuartersIncluded: Function
}

export default function StepSelectData ({ tabs, forInternational = false, selectedDataFields, setSelectedDataFields, quartersIncluded, setQuartersIncluded }: Props): JSX.Element {
  const intl = useIntl()

  const [activePage] = useSessionStorage('activePage', '/')
  const [activeCategoryId, setActiveCategoryId] = useState(-1)
  const [dataCategories, setDataCategories] = useState<DataCategory[]>([])

  const quarterSelectItems: SelectItem[] = [
    {
      value: 1,
      label: intl.formatMessage({ id: 'selectData.quarterSelect.1' })
    },
    {
      value: 4,
      label: intl.formatMessage({ id: 'selectData.quarterSelect.4' })
    },
    {
      value: 8,
      label: intl.formatMessage({ id: 'selectData.quarterSelect.8' })
    }
  ]

  const menuItems: MenuItem[] = useMemo(() => dataCategories.map(x => ({
    id: x.categoryID,
    label: x.category,
    icon: x.iconName
  })), [dataCategories])

  const category: DataCategory | undefined = useMemo(() => dataCategories.find(x => x.categoryID === activeCategoryId), [dataCategories, activeCategoryId])

  const dataFields: DataField[] = useMemo(() => category?.dataFields ?? [], [category])

  const allSelected = useMemo(() => !dataFields.some(x => !selectedDataFields.map(x => x.dataFieldID).includes(x.dataFieldID)), [dataFields, selectedDataFields])

  const activeMenuItemIds = useMemo(() => {
    const selectedDataFieldIds = selectedDataFields.map(x => x.dataFieldID)
    return dataCategories.filter(x => x.dataFields.some(y => selectedDataFieldIds.includes(y.dataFieldID))).map(x => x.categoryID)
  }, [menuItems, selectedDataFields])

  const checkboxStyle = prepareStyle(utils => ({
    display: 'inline-flex'
  }))

  const tooltipStyle = prepareStyle(utils => ({
    selectors: {
      '& .tippy-arrow::before': {
        content: 'none'
      }
    }
  }))

  const tooltipIconStyle = prepareStyle(utils => ({
    marginLeft: '0.5rem'
  }))

  useEffect(() => {
    async function getFields (): Promise<void> {
      const { allDataFields, error } = await apiGet('getDataFields', {
        params: {
          forInternational: forInternational ? 1 : 0
        }
      })

      if (error !== undefined) return

      allDataFields.sort((a: {categoryPosition: number}, b: {categoryPosition: number}) => a.categoryPosition - b.categoryPosition)

      setDataCategories(allDataFields)
    }

    getFields().catch(console.error)
  }, [intl.locale])

  function onMenuClick (item: MenuItem): void {
    setActiveCategoryId(item.id)
  }

  function onCheckboxChange (field: DataField, checked: boolean = false): void {
    if (activeCategoryId === 6 || (activeCategoryId === 1 && field.dataFieldName?.startsWith('mta_'))) {
      field.quartersIncluded = quartersIncluded
    } else {
      field.quartersIncluded = null
    }

    if (checked) {
      setSelectedDataFields([...new Map([...selectedDataFields, field].map(x => [x.dataFieldID, x])).values()])
    } else {
      setSelectedDataFields(selectedDataFields.filter(x => x.dataFieldID !== field.dataFieldID))
    }
  }

  function onCheckAllChange (checked: boolean = false): void {
    if (checked) {
      setSelectedDataFields([...new Map([...selectedDataFields, ...dataFields.map(x => {
        if (activeCategoryId === 6 || (activeCategoryId === 1 && x.dataFieldName?.startsWith('mta_'))) {
          x.quartersIncluded = quartersIncluded
        } else {
          x.quartersIncluded = null
        }

        return x
      })].map(x => [x.dataFieldID, x])).values()])
    } else {
      setSelectedDataFields(selectedDataFields.filter(x => !dataFields.map(x => x.dataFieldID).includes(x.dataFieldID)))
    }
  }

  function onQuarterChange (label: string): void {
    const value = quarterSelectItems.find(x => x.label === label)?.value ?? 1

    setQuartersIncluded(value)
    setSelectedDataFields(selectedDataFields.map(x => ({ ...x, quartersIncluded: x.quartersIncluded !== null && activeCategoryId === 6 ? value : null })))
  }

  return (
    <div className="w-full my-14 lg:ml-96 lg:px-11 place-self-start">
      <div className="mb-8 md:mb-14">
        <Text
          message={{ id: 'selectData.title' }}
          variant="viewTitle"
        />
      </div>

      <StepsIndicator
        activeStep={1}
        steps={tabs}
      />

      <Menu
        activeItemIds={activeMenuItemIds}
        disableChildrenSelect
        items={menuItems}
        noSelectedCategoryForMobile
        onClick={onMenuClick}
        titleMessageId="selectData.menuTitle"
      />

      <div className="my-8 pt-8 font-bold border-t-[3px] border-red-brand text-[18px]">
        {category?.category}
      </div>

      <div className={`${activeCategoryId !== 6 && 'hidden'} lg:w-1/4 mb-8`}>
        <Select
          isCombobox={false}
          items={quarterSelectItems.map(x => x.label)}
          nilMessage={intl.formatMessage({ id: 'global.selectCategory' })}
          onChange={function (e) { onQuarterChange(e ?? '') }}
          sorting="none"
          value={quarterSelectItems.find(x => x.value === quartersIncluded)?.label ?? undefined}
        />
      </div>

      {/* Mobile view will only show if category is selected */}
      {!!dataFields.length && (
        <div className="mb-3">
          <Checkbox
            customStyle={checkboxStyle}
            isChecked={allSelected}
            label={intl.formatMessage({ id: 'selectData.selectAll' })}
            onChange={function (e) { onCheckAllChange(e.target.checked) }}
          />
        </div>
      )}

      {dataFields.map(field => (
        <div
          className="mb-3"
          key={field.dataFieldID}
        >
          <Checkbox
            customStyle={checkboxStyle}
            isChecked={selectedDataFields.some(x => x.dataFieldID === field.dataFieldID)}
            label={field.dataFieldText}
            onChange={function (e) { onCheckboxChange(field, e.target.checked) }}
          />
          {activePage !== '/global-sample' && ['main_activity', 'other_activities', 'revenue_activities'].includes(field.dataFieldName)
            ? (
              <Tooltip
                customStyle={tooltipStyle}
                maxWidth='350px'
                offset={5}
                shouldPreserveCase
                tooltip={
                  intl.formatMessage({ id: 'selectData.activity.info' })
                }
              >
                <Icon
                  customStyle={tooltipIconStyle}
                  type="info"
                />
              </Tooltip>
              )
            : null}
        </div>
      ))}
    </div>
  )
}
